var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{staticStyle:{"height":"calc(100% - 55px)","overflow":"auto","padding-bottom":"53px"},attrs:{"title":"新增角色","maskClosable":false,"width":"650","placement":"right","closable":false,"visible":_vm.roleAddVisiable},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',_vm._b({attrs:{"label":"角色名称","validateStatus":_vm.validateStatus,"help":_vm.help}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['roleName']),expression:"['roleName']"}],on:{"blur":_vm.handleRoleNameBlur}})],1),_c('a-form-item',_vm._b({attrs:{"label":"角色描述"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'remark',
        {rules: [
          { max: 50, message: '长度不能超过50个字符'}
        ]}]),expression:"[\n        'remark',\n        {rules: [\n          { max: 50, message: '长度不能超过50个字符'}\n        ]}]"}],attrs:{"rows":4}})],1),_c('a-form-item',_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"数据权限"}},'a-form-item',_vm.formItemLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'dataScope',
        {rules: [
        ]}]),expression:"[\n        'dataScope',\n        {rules: [\n        ]}]"}],attrs:{"name":"dataScope","options":_vm.options}})],1),_c('a-form-item',_vm._b({staticStyle:{"margin-bottom":"2rem"},attrs:{"label":"权限选择","validateStatus":_vm.menuSelectStatus,"help":_vm.menuSelectHelp}},'a-form-item',_vm.formItemLayout,false),[_c('a-tree',{key:_vm.menuTreeKey,ref:"menuTree",attrs:{"checkable":true,"checkStrictly":_vm.checkStrictly,"expandedKeys":_vm.expandedKeys,"treeData":_vm.menuTreeData},on:{"check":_vm.handleCheck,"expand":_vm.handleExpand}})],1)],1),_c('div',{staticClass:"drawer-bootom-button"},[_c('a-dropdown',{staticStyle:{"float":"left"},attrs:{"trigger":['click'],"placement":"topCenter"}},[_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"1",on:{"click":_vm.expandAll}},[_vm._v("展开所有")]),_c('a-menu-item',{key:"2",on:{"click":_vm.closeAll}},[_vm._v("合并所有")]),_c('a-menu-item',{key:"3",on:{"click":_vm.enableRelate}},[_vm._v("父子关联")]),_c('a-menu-item',{key:"4",on:{"click":_vm.disableRelate}},[_vm._v("取消关联")])],1),_c('a-button',[_vm._v(" 树操作 "),_c('a-icon',{attrs:{"type":"up"}})],1)],1),_c('a-button',{staticStyle:{"margin-right":".8rem"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }